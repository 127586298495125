import React from "react";
import {Scatter} from 'react-chartjs-2';
import {ResultChartTemplate} from "../../template/ResultChartTemplate";

export interface ResultInterface {
  x: number,
  y: number,
}

export const ResultChart = (props: ResultInterface) => {
  return (

      <ResultChartTemplate topLabel="Libertà"
                           rightLabel="Amore"
                           bottomLabel="Controllo"
                           leftLabel="Odio"
          // @ts-ignore
                           chart={<Scatter height={null} width={null} data={data(props.x, props.y)} options={options}/>}
      />
  )
}

const data = (xResult: number, yResult: number) =>  {
  return {
    datasets: [
      {
        data: [
          { x: xResult.toFixed(2), y: yResult.toFixed(2) },
        ],
        backgroundColor: 'rgba(0, 0, 0, 1)',
      },
    ],
  }
}
// how to configure axes: https://www.chartjs.org/docs/latest/axes/cartesian/linear.html?h=beginatzero
// how to configure point: https://www.chartjs.org/docs/latest/charts/line.html#dataset-properties
// how to configure background lines: https://www.chartjs.org/docs/latest/axes/styling.html#grid-line-configuration
// how to configure axes label: https://www.chartjs.org/docs/latest/axes/labelling.html#scale-title-configuration
// how to configure axes label: https://www.chartjs.org/docs/latest/configuration/legend.html

const options = {
  aspectRatio: 1,
  scales: {
    yAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: false,
          suggestedMax: 2,
          suggestedMin: -2,
          stepSize: 1,
          maxTicksLimit: 41  // the number of ticks between -2 and +2 if there is one tick for each 0.1
        },
        gridLines : {
          display: true,
          lineWidth: 0
        },
      }
    ],
    xAxes: [
      {
        ticks: {
          display: false,
          beginAtZero: false,
          suggestedMax: 2,
          suggestedMin: -2,
          stepSize: 1,
          maxTicksLimit: 41  // the number of ticks between -2 and +2 if there is one tick for each 0.1
        },
        gridLines : {
          display: true,
          lineWidth: 0
        },
      }    ]
  },
  legend : {
    display : false
  },
  elements: {
    point: {
      radius: 6,
    }
  }
}