import * as queryString from "querystring";
import {useHistory, useLocation} from "react-router-dom";
import {LocationState, Path} from "history";
import {ParsedUrlQueryInput} from "querystring";

export interface FeatureToggles {
}

export const  useFeatureToggles = ()  => {
  return queryString.parse(useLocation().search.slice(1)) as FeatureToggles;
}

export const useRedirectTo = () => {
  const featureToggles = useFeatureToggles()
  const history = useHistory()
  const featureTogglesString= queryString.stringify(featureToggles as ParsedUrlQueryInput)

  return (url: Path, state?: LocationState) => history.push(`${url}?${featureTogglesString}`, state)

}