import React, {useState} from "react";
import {ButtonInterface} from "../../components/CustomButton";
import {Notification} from "../../components/Notification";
import {PageLayout} from "../../template/PageLayout";
import {SymbolRowTemplate} from "../../template/SymbolRowTemplate";
import {ColorStep, Step, WhereStep} from "./steps";
import {isValidStep, nextStepAction, previousStepAction, selectSymbolAction} from './actions'
import {DreamFormNavigationBarTemplate} from "../../template/DreamFormNavigationBarTemplate";
import {Symbol} from "./symbols";
import {SymbolGridTemplate} from "../../template/SymbolGridTemplate";
import ReactGA from 'react-ga';
import {useRedirectTo} from "../../helpers/hooks";

const DreamForm = () => {
  const redirectTo = useRedirectTo()

  const [alert, setAlert] = useState<string | null>(null)
  const [step, setStep] = useState<Step>(ColorStep)
  const [symbols, setSymbols] = useState(step.symbols)
  const [selectedSymbols, setSelectedSymbols] = useState<Symbol[]>([])
  const [selectedWhereSymbols, setSelectedWhereSymbols] = useState<Symbol[]>([])
  const [currentSymbols, currentSymbolsSetter] = step === WhereStep
      ? [selectedWhereSymbols, setSelectedWhereSymbols]
      : [selectedSymbols, setSelectedSymbols]

  const onSelectSymbol = (selected: Symbol) => {
    const newSelectedSymbols = selectSymbolAction(selected, currentSymbols)
    currentSymbolsSetter(newSelectedSymbols)
  }

  const pushToResult = () => {
    ReactGA.event({
      category: "Dream",
      action: "Result"
    });
    redirectTo("/result", {selectedSymbols, selectedWhereSymbols})
  }

  const onClickNext = () => {
    const [valid, message] = isValidStep(step, currentSymbols)
    if (!valid) {
      message && setAlert(message)
      return
    }

    if (step.next) {
      const [nextStep, nextSymbols] = nextStepAction(selectedWhereSymbols, step)
      setStep(nextStep)
      setSymbols(nextSymbols)
    } else {
      pushToResult()
    }
  }

  const onClickBack = () => {
    const [nextStep, nextSymbols] = previousStepAction(step)
    nextStep && setStep(nextStep)
    nextSymbols && setSymbols(nextSymbols)
  }

  const nextButton: ButtonInterface = {
    label: step.next ? "Avanti" : "Interpreta",
    onClick: () => onClickNext(),
    dataTestId: "next-step-button"
  };

  const stepProps = {
    selectedSymbols: currentSymbols,
    onClick: onSelectSymbol,
    symbols
  }

  return (
      <PageLayout
          clickOnLogo={() => redirectTo("/")}>
        <DreamFormNavigationBarTemplate onClickBack={onClickBack} step={step} topRightButton={nextButton}>
            <Notification
                isOpen={alert !== null}
                onClose={() => setAlert(null)}
                message={alert}
            />
            {step === WhereStep ?
                <SymbolRowTemplate
                    {...stepProps}/> :
                <SymbolGridTemplate
                    {...stepProps}/>
            }
        </DreamFormNavigationBarTemplate>
      </PageLayout>

  )
};

export default DreamForm