import {InterpretableSymbol, NonInterpretableSymbol, Symbol} from "../dream-form/symbols";
import {INSIDE_RESULT_CHART, OUTSIDE_RESULT_CHART, Quadrant, ResultIcon} from "./icons";
import api from "../../helpers/api";
import {getWhereStepCondition} from "../dream-form/actions";
import {WhereStepCondition} from "../dream-form/steps";

export const calculateAverage = (selectedSymbols: InterpretableSymbol[]) => {
  const xValues = selectedSymbols.map(symbol => symbol.x)
  const yValues = selectedSymbols.map(symbol => symbol.y)

  const xSum = xValues.reduce((currentSum, element) => currentSum + element, 0)
  const ySum = yValues.reduce((currentSum, element) => currentSum + element, 0)

  const xAverage = xValues.length > 0 ? xSum / xValues.length : undefined
  const yAverage = yValues.length > 0 ? ySum / yValues.length : undefined
  return [xAverage, yAverage]
}

export const calculateQuadrantFromAverages = (x: number, y: number) => {
  if(x>=0 && y>= 0) return Quadrant.topRightQuadrant
  if(x>=0 && y< 0) return Quadrant.bottomRightQuadrant
  if(x<0 && y< 0) return Quadrant.bottomLeftQuadrant
  return Quadrant.topLeftQuadrant

}
export const iconFromAverages = (x: number, y: number, isInside: boolean, numberOfSymbols: number): ResultIcon => {
  const index = numberOfSymbols > 7 ? 4
      : numberOfSymbols > 6 ? 3
          : numberOfSymbols > 5 ? 2
              : numberOfSymbols > 4 ? 1
                  : 0

  if (isInside) {
    return insideIcons(x, y, index)
  }
  return outsideIcons(x, y, index)

}

export const precisionFromNumberOfSymbols = (numberOfSymbols: number): number => {
  return numberOfSymbols > 7 ? 85
      : numberOfSymbols > 6 ? 75
          : numberOfSymbols > 5 ? 65
              : numberOfSymbols > 4 ? 55
                  : 45
}

const insideIcons = (x: number, y: number, index: number): ResultIcon => {
  if (x >= 0 && x < 1 && y >= 1)
    return INSIDE_RESULT_CHART.topRightQuadrant.topLeft[index]
  if (x >= 1 && y >= 1)
    return INSIDE_RESULT_CHART.topRightQuadrant.topRight[index]
  if (x >= 0 && x < 1 && y >= 0 && y < 1)
    return INSIDE_RESULT_CHART.topRightQuadrant.bottomLeft[index]
  if (x >= 1 && y >= 0 && y < 1)
    return INSIDE_RESULT_CHART.topRightQuadrant.bottomRight[index]

  if (x >= 0 && x < 1 && y >= -1 && y < 0)
    return INSIDE_RESULT_CHART.bottomRightQuadrant.topLeft[index]
  if (x >= 1 && y >= -1 && y < 0)
    return INSIDE_RESULT_CHART.bottomRightQuadrant.topRight[index]
  if (x >= 0 && x < 1 && y < -1)
    return INSIDE_RESULT_CHART.bottomRightQuadrant.bottomLeft[index]
  if (x >= 1 && y < -1)
    return INSIDE_RESULT_CHART.bottomRightQuadrant.bottomRight[index]


  if (x < -1 && y >= -1 && y < 0)
    return INSIDE_RESULT_CHART.bottomLeftQuadrant.topLeft[index]
  if (x >= -1 && x < 0 && y >= -1 && y < 0)
    return INSIDE_RESULT_CHART.bottomLeftQuadrant.topRight[index]
  if (x < -1 && y < -1)
    return INSIDE_RESULT_CHART.bottomLeftQuadrant.bottomLeft[index]
  if (x >= -1 && x < 0 && y < -1)
    return INSIDE_RESULT_CHART.bottomLeftQuadrant.bottomRight[index]

  if (x < -1 && y >= 1)
    return INSIDE_RESULT_CHART.topLeftQuadrant.topLeft[index]
  if (x >= -1 && x < 0 && y >= 1)
    return INSIDE_RESULT_CHART.topLeftQuadrant.topRight[index]
  if (x < -1 && y >= 0 && y < 1)
    return INSIDE_RESULT_CHART.topLeftQuadrant.bottomLeft[index]
  if (x >= -1 && x < 0 && y >= 0 && y < 1)
    return INSIDE_RESULT_CHART.topLeftQuadrant.bottomRight[index]

  return INSIDE_RESULT_CHART.topLeftQuadrant.topLeft[0]
}

const outsideIcons = (x: number, y: number, index: number): ResultIcon => {
  if (x >= 0 && x < 1 && y >= 1)
    return OUTSIDE_RESULT_CHART.topRightQuadrant.topLeft[index]
  if (x >= 1 && y >= 1)
    return OUTSIDE_RESULT_CHART.topRightQuadrant.topRight[index]
  if (x >= 0 && x < 1 && y >= 0 && y < 1)
    return OUTSIDE_RESULT_CHART.topRightQuadrant.bottomLeft[index]
  if (x >= 1 && y >= 0 && y < 1)
    return OUTSIDE_RESULT_CHART.topRightQuadrant.bottomRight[index]

  if (x >= 0 && x < 1 && y >= -1 && y < 0)
    return OUTSIDE_RESULT_CHART.bottomRightQuadrant.topLeft[index]
  if (x >= 1 && y >= -1 && y < 0)
    return OUTSIDE_RESULT_CHART.bottomRightQuadrant.topRight[index]
  if (x >= 0 && x < 1 && y < -1)
    return OUTSIDE_RESULT_CHART.bottomRightQuadrant.bottomLeft[index]
  if (x >= 1 && y < -1)
    return OUTSIDE_RESULT_CHART.bottomRightQuadrant.bottomRight[index]


  if (x < -1 && y >= -1 && y < 0)
    return OUTSIDE_RESULT_CHART.bottomLeftQuadrant.topLeft[index]
  if (x >= -1 && x < 0 && y >= -1 && y < 0)
    return OUTSIDE_RESULT_CHART.bottomLeftQuadrant.topRight[index]
  if (x < -1 && y < -1)
    return OUTSIDE_RESULT_CHART.bottomLeftQuadrant.bottomLeft[index]
  if (x >= -1 && x < 0 && y < -1)
    return OUTSIDE_RESULT_CHART.bottomLeftQuadrant.bottomRight[index]

  if (x < -1 && y >= 1)
    return OUTSIDE_RESULT_CHART.topLeftQuadrant.topLeft[index]
  if (x >= -1 && x < 0 && y >= 1)
    return OUTSIDE_RESULT_CHART.topLeftQuadrant.topRight[index]
  if (x < -1 && y >= 0 && y < 1)
    return OUTSIDE_RESULT_CHART.topLeftQuadrant.bottomLeft[index]
  if (x >= -1 && x < 0 && y >= 0 && y < 1)
    return OUTSIDE_RESULT_CHART.topLeftQuadrant.bottomRight[index]

  return OUTSIDE_RESULT_CHART.topLeftQuadrant.topLeft[0]
}

export const saveDream = (x: number, y: number, symbols: Symbol[]) => {
  const symbolIds = symbols.map(symbol => symbol.id)
  api.saveDream({x, y, symbolIds})
}

export const isInsideFirst = (whereSymbols: NonInterpretableSymbol[]) => {
  const whereStepCondition = getWhereStepCondition(whereSymbols)
  return whereStepCondition === WhereStepCondition.FirstInsideThenOutside
  || whereStepCondition === WhereStepCondition.InsideOnly
}