import React from "react";
import {Grid} from "@material-ui/core";
import {Symbol} from "../../pages/dream-form/symbols";
import {SymbolCard} from "../../components/SymbolCard";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface SymbolRowTemplateInterface {
  symbols: Symbol[],
  selectedSymbols: Symbol[],
  onClick: (symbol: Symbol) => void,
}

const useStyles = makeStyles((theme) => ({
  symbolRowGrid: {paddingTop: '10px'}
}));

export const SymbolRowTemplate = (props: SymbolRowTemplateInterface) => {
  const classes = useStyles();
  const isSymbolSelected = (symbolId: string) => {
    return !!props.selectedSymbols.find(symbol => symbol.id === symbolId)
  }

  return (
<>
      {props.symbols.map((symbol, index) =>
        <Grid
          item
          xs={12}
          className={classes.symbolRowGrid}
          key={`symbol-${index}`} >
          <SymbolCard label={symbol.label}
                      symbolSize={300}
                      symbolImage={symbol.icon}
                      symbolBackground={symbol.backgroundImage}
                      isSelected={isSymbolSelected(symbol.id)}
                      onClick={() => props.onClick(symbol)}/>
        </Grid>)
      }
      </>
  )
}