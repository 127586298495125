export enum SymbolId {
  domesticAnimals = "domestic_animals",
  wildAnimals = "wild_animals",
  fish = "fish",
  insects = "insects",
  reptiles = "reptiles",
  birds = "birds",
  beardAndMoustache = "beard_and_moustache",
  mouth = "mouth",
  arm = "arm",
  hair = "hair",
  teeth = "teeth",
  finger = "finger",
  tongue = "tongue",
  hand = "hand",
  nose = "nose",
  eye = "eye",
  ear = "ear",
  breast = "breast",
  foot = "foot",
  head = "head",
  nail = "nail",
  orange = "orange",
  white = "white",
  blue = "blue",
  yellow = "yellow",
  black = "black",
  pink = "pink",
  red = "red",
  green = "green",
  violet = "violet",
  bathroom = "bathroom",
  bedroom = "bedroom",
  house = "house",
  kitchen = "kitchen",
  game = "game",
  laundry = "laundry",
  library = "library",
  warehouse = "warehouse",
  music = "music",
  shop = "shop",
  livingroom = "livingroom",
  office = "office",
  trees = "trees",
  countryside = "countryside",
  castle = "castle",
  church = "church",
  city = "city",
  flowers = "flowers",
  river = "river",
  lake = "lake",
  sea = "sea",
  mountain = "mountain",
  park = "park",
  vehicles = "vehicles",
  dress = "dress",
  ring = "ring",
  bag = "bag",
  socks = "socks",
  shirt = "shirt",
  hat = "hat",
  necklace = "necklace",
  tie = "tie",
  jacket = "jacket",
  skirt = "skirt",
  gloves = "gloves",
  underwear = "underwear",
  glasses = "glasses",
  umbrella = "umbrella",
  earrings = "earrings",
  watch = "watch",
  trousers = "trousers",
  shoes = "shoes",
  uniform = "uniform",
  suitcase = "suitcase",
  veil = "veil",
  water = "water",
  beer = "beer",
  coffee = "coffee",
  meat = "meat",
  cocktail = "cocktail",
  seasoning = "seasoning",
  cookie = "cookie",
  fruits = "fruits",
  milk = "milk",
  bread = "bread",
  pasta = "pasta",
  juice = "juice",
  alcohol = "alcohol",
  tea = "tea",
  egg = "egg",
  vegetables = "vegetables",
  wine = "wine",
  soup = "soup",
  autumn = "autumn",
  summer = "summer",
  daytime = "daytime",
  winter = "winter",
  morning = "morning",
  fog = "fog",
  snow = "snow",
  night = "night",
  clouds = "clouds",
  rain = "rain",
  spring = "spring",
  evening = "evening",
  sunny = "sunny",
  stars = "stars",
  wind = "wind",
  outside = "out",
  inside = "in"
}

export interface NonInterpretableSymbol {
  icon: string | null
  id: SymbolId
  label: string
  backgroundImage?: string
}

export interface InterpretableSymbol extends NonInterpretableSymbol {
  x: number
  y: number
}

export type Symbol = NonInterpretableSymbol | InterpretableSymbol

export const Where: NonInterpretableSymbol[] = [
  {
    icon: null,
    id: SymbolId.inside,
    label: "All'interno",
    backgroundImage: "/inside.jpeg"
  },
  {
    icon: null,
    id: SymbolId.outside,
    label: "All'aperto",
    backgroundImage: "/outside.jpeg"
  }
]

export const Colors: InterpretableSymbol[] = [
  {icon: "/symbols/orange.png", label: "Arancione", id: SymbolId.orange, x: 2, y: 1},
  {icon: "/symbols/white.png", label: "Bianco", id: SymbolId.white, x: 1, y: 2},
  {icon: "/symbols/blue.png", label: "Blu", id: SymbolId.blue, x: 1, y: 1},
  {icon: "/symbols/yellow.png", label: "Giallo", id: SymbolId.yellow, x: 1, y: -1},
  {icon: "/symbols/black.png", label: "Nero", id: SymbolId.black, x: -2, y: -2},
  {icon: "/symbols/pink.png", label: "Rosa", id: SymbolId.pink, x: 1, y: 2},
  {icon: "/symbols/red.png", label: "Rosso", id: SymbolId.red, x: 2, y: -1},
  {icon: "/symbols/green.png", label: "Verde", id: SymbolId.green, x: 1, y: 1},
  {icon: "/symbols/purple.png", label: "Viola", id: SymbolId.violet, x: -1, y: -2},
]

export const When: InterpretableSymbol[] = [
  {icon: "/symbols/spring.png", label: "Primavera", id: SymbolId.spring, x: 2, y: 2},
  {icon: "/symbols/summer.png", label: "Estate", id: SymbolId.summer, x: 2, y: 1},
  {icon: "/symbols/autumn.png", label: "Autunno", id: SymbolId.autumn, x: -1, y: -1},
  {icon: "/symbols/winter.png", label: "Inverno", id: SymbolId.winter, x: -2, y: -2},
  {icon: "/symbols/sun.png", label: "Giorno", id: SymbolId.daytime, x: 1, y: 1},
  {icon: "/symbols/sunrise.png", label: "Mattina", id: SymbolId.morning, x: 1, y: 1},
  {icon: "/symbols/fog.png", label: "Nebbia", id: SymbolId.fog, x: -1, y: -2},
  {icon: "/symbols/snow.png", label: "Neve", id: SymbolId.snow, x: 1, y: -2},
  {icon: "/symbols/moonset.png", label: "Notte", id: SymbolId.night, x: -1, y: -2},
  {icon: "/symbols/cloud.png", label: "Nuvoloso", id: SymbolId.clouds, x: -1, y: -1},
  {icon: "/symbols/rain.png", label: "Pioggia", id: SymbolId.rain, x: -1, y: -2},
  {icon: "/symbols/sunset.png", label: "Sera", id: SymbolId.evening, x: 1, y: -1},
  {icon: "/symbols/sun.png", label: "Soleggiato", id: SymbolId.sunny, x: 2, y: 2},
  {icon: "/symbols/star.png", label: "Stelle", id: SymbolId.stars, x: 2, y: -1},
  {icon: "/symbols/wind.png", label: "Vento", id: SymbolId.wind, x: -1, y: 2},
]

export const WhereOutside: InterpretableSymbol[] = [
  {icon: "/symbols/tree.png", label: "Alberi", id: SymbolId.trees, x: 1, y: -1},
  {icon: "/symbols/countryside.png", label: "Campagna", id: SymbolId.countryside, x: 1, y: 1},
  {icon: "/symbols/castle.png", label: "Castello", id: SymbolId.castle, x: 2, y: -2},
  {icon: "/symbols/church.png", label: "Chiesa", id: SymbolId.church, x: 1, y: -2},
  {icon: "/symbols/city.png", label: "Città", id: SymbolId.city, x: 1, y: -1},
  {icon: "/symbols/flower.png", label: "Fiori", id: SymbolId.flowers, x: 2, y: 2},
  {icon: "/symbols/river.png", label: "Fiume", id: SymbolId.river, x: 1, y: 2},
  {icon: "/symbols/lake.png", label: "Lago", id: SymbolId.lake, x: 1, y: -1},
  {icon: "/symbols/sea.png", label: "Mare", id: SymbolId.sea, x: 2, y: 1},
  {icon: "/symbols/mountain.png", label: "Montagna", id: SymbolId.mountain, x: 2, y: -2},
  {icon: "/symbols/park.png", label: "Parco", id: SymbolId.park, x: 1, y: 2},
  {icon: "/symbols/vehicles.png", label: "Veicoli", id: SymbolId.vehicles, x: 1, y: -1},
]

export const WhereInside: InterpretableSymbol[] = [
  {icon: "/symbols/bathroom.png", label: "Bagno", id: SymbolId.bathroom, x: 1, y: -1},
  {icon: "/symbols/bedroom.png", label: "Camera da letto", id: SymbolId.bedroom, x: 1, y: 1},
  {icon: "/symbols/house.png", label: "Casa", id: SymbolId.house, x: 2, y: -1},
  {icon: "/symbols/kitchen.png", label: "Cucina", id: SymbolId.kitchen, x: 2, y: -1},
  {icon: "/symbols/game.png", label: "Giochi", id: SymbolId.game, x: 1, y: -1},
  {icon: "/symbols/laundry.png", label: "Lavanderia", id: SymbolId.laundry, x: -1, y: -2},
  {icon: "/symbols/library.png", label: "Libreria", id: SymbolId.library, x: 1, y: 1},
  {icon: "/symbols/warehouse.png", label: "Magazzino", id: SymbolId.warehouse, x: -2, y: -1},
  {icon: "/symbols/music.png", label: "Musica", id: SymbolId.music, x: 1, y: 2},
  {icon: "/symbols/shop.png", label: "Negozio", id: SymbolId.shop, x: 1, y: -1},
  {icon: "/symbols/living_room.png", label: "Salotto", id: SymbolId.livingroom, x: 1, y: -1},
  {icon: "/symbols/office.png", label: "Ufficio", id: SymbolId.office, x: -1, y: -2},
]


export const Animals: InterpretableSymbol[] = [
  {icon: "/symbols/domestic.png", label: "Animali domestici", id: SymbolId.domesticAnimals, x: 1, y: -1},
  //manca
  {icon: "/symbols/lion.png", label: "Animali selvaggi", id: SymbolId.wildAnimals, x: -1, y: -2},
  //manca
  {icon: "/symbols/fish.png", label: "Creature acquatiche", id: SymbolId.fish, x: -1, y: 1},
  //manca
  {icon: "/symbols/fly.png", label: "Insetti e aracnidi", id: SymbolId.insects, x: -1, y: 1},
  //manca
  {icon: "/symbols/snake.png", label: "Rettili e anfibi", id: SymbolId.reptiles, x: -2, y: -1},
  {icon: "/symbols/birds.png", label: "Uccelli", id: SymbolId.birds, x: 1, y: 2},
]

export const Body: InterpretableSymbol[] = [
  {icon: "/symbols/mustache.png", label: "Barba e baffi", id: SymbolId.beardAndMoustache, x: 1, y: -1},
  {icon: "/symbols/mouth.png", label: "Bocca e labbra", id: SymbolId.mouth, x: 2, y: 1},
  {icon: "/symbols/arm.png", label: "Braccio", id: SymbolId.arm, x: 1, y: -2},
  {icon: "/symbols/hair.png", label: "Capelli", id: SymbolId.hair, x: 1, y: 1},
  {icon: "/symbols/tooth.png", label: "Denti", id: SymbolId.teeth, x: -1, y: -2},
  {icon: "/symbols/finger.png", label: "Dito", id: SymbolId.finger, x: -1, y: -1},
  {icon: "/symbols/tounge.png", label: "Lingua", id: SymbolId.tongue, x: 1, y: 2},
  {icon: "/symbols/hand.png", label: "Mano", id: SymbolId.hand, x: 2, y: 1},
  {icon: "/symbols/nose.png", label: "Naso", id: SymbolId.nose, x: -2, y: -1},
  {icon: "/symbols/eye.png", label: "Occhio", id: SymbolId.eye, x: 2, y: -1},
  {icon: "/symbols/ear.png", label: "Orecchio", id: SymbolId.ear, x: -1, y: 2},
  {icon: "/symbols/breast.png", label: "Petto", id: SymbolId.breast, x: 1, y: 1},
  {icon: "/symbols/foot.png", label: "Piede", id: SymbolId.foot, x: 1, y: 1},
  {icon: "/symbols/head.png", label: "Testa", id: SymbolId.head, x: 2, y: -1},
  {icon: "/symbols/nail.png", label: "Unghia e artiglio", id: SymbolId.nail, x: -2, y: -1},
]

export const Dress: InterpretableSymbol[] = [
  {icon: "/symbols/dress.png", label: "Abito", id: SymbolId.dress, x: 1, y: 1},
  {icon: "/symbols/ring.png", label: "Anello", id: SymbolId.ring, x: 2, y: -2},
  {icon: "/symbols/bag.png", label: "Borsa e borsetta", id: SymbolId.bag, x: -1, y: -1},
  {icon: "/symbols/socks.png", label: "Calze", id: SymbolId.socks, x: 1, y: -1},
  {icon: "/symbols/shirt.png", label: "Camicia", id: SymbolId.shirt, x: -1, y: -2},
  {icon: "/symbols/hat.png", label: "Cappello copricapo", id: SymbolId.hat, x: -1, y: -1},
  {icon: "/symbols/necklace.png", label: "Collana", id: SymbolId.necklace, x: 1, y: -2},
  {icon: "/symbols/tie.png", label: "Cravatta", id: SymbolId.tie, x: 1, y: -1},
  {icon: "/symbols/jacket.png", label: "Giacca", id: SymbolId.jacket, x: 1, y: 2},
  {icon: "/symbols/skirt.png", label: "Gonna", id: SymbolId.skirt, x: 1, y: 1},
  {icon: "/symbols/gloves.png", label: "Guanti", id: SymbolId.gloves, x: 1, y: -2},
  {icon: "/symbols/underwear.png", label: "Intimo", id: SymbolId.underwear, x: 1, y: -1},
  {icon: "/symbols/glasses.png", label: "Occhiali", id: SymbolId.glasses, x: -2, y: 1},
  {icon: "/symbols/umbrella.png", label: "Ombrello e parasole", id: SymbolId.umbrella, x: -1, y: -1},
  {icon: "/symbols/earring.png", label: "Orecchini", id: SymbolId.earrings, x: 2, y: 1},
  //manca
  {icon: "/symbols/clock.png", label: "Orologio da polso", id: SymbolId.watch, x: -1, y: -2},
  {icon: "/symbols/trousers.png", label: "Pantaloni", id: SymbolId.trousers, x: -1, y: -1},
  {icon: "/symbols/shoe.png", label: "Scarpe", id: SymbolId.shoes, x: 1, y: 1},
  {icon: "/symbols/uniform.png", label: "Uniforme", id: SymbolId.uniform, x: -1, y: -2},
  {icon: "/symbols/suitcase.png", label: "Valigia", id: SymbolId.suitcase, x: 1, y: 2},
  {icon: "/symbols/veil.png", label: "Velo", id: SymbolId.veil, x: -1, y: -1},
]

export const FoodDrinks: InterpretableSymbol[] = [
  {icon: "/symbols/water.png", label: "Acqua", id: SymbolId.water, x: 1, y: 1},
  {icon: "/symbols/beer.png", label: "Birra", id: SymbolId.beer, x: 1, y: -1},
  {icon: "/symbols/coffee.png", label: "Caffè", id: SymbolId.coffee, x: 1, y: 1},
  {icon: "/symbols/meat.png", label: "Carne", id: SymbolId.meat, x: -1, y: 1},
  {icon: "/symbols/cocktail.png", label: "Cocktail", id: SymbolId.cocktail, x: 1, y: 2},
  {icon: "/symbols/seasoning.png", label: "Condimenti", id: SymbolId.seasoning, x: 1, y: 1},
  {icon: "/symbols/cookies.png", label: "Dolci", id: SymbolId.cookie, x: 2, y: 1},
  {icon: "/symbols/fruits.png", label: "Frutta", id: SymbolId.fruits, x: 2, y: 2},
  {icon: "/symbols/milk.png", label: "Latte", id: SymbolId.milk, x: 1, y: 1},
  {icon: "/symbols/bread.png", label: "Pane", id: SymbolId.bread, x: 2, y: 1},
  {icon: "/symbols/pasta.png", label: "Pasta", id: SymbolId.pasta, x: 1, y: 1},
  {icon: "/symbols/juice.png", label: "Succo", id: SymbolId.juice, x: 2, y: 1},
  {icon: "/symbols/alcohol.png", label: "Super alcoolici", id: SymbolId.alcohol, x: 1, y: -1},
  {icon: "/symbols/tea.png", label: "The", id: SymbolId.tea, x: 1, y: 1},
  {icon: "/symbols/egg.png", label: "Uovo", id: SymbolId.egg, x: 1, y: -2},
  {icon: "/symbols/vegetables.png", label: "Verdure", id: SymbolId.vegetables, x: 1, y: 1},
  {icon: "/symbols/wine.png", label: "Vino", id: SymbolId.wine, x: 2, y: 1},
  {icon: "/symbols/soup.png", label: "Zuppa", id: SymbolId.soup, x: 1, y: -2},
]