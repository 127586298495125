import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {InterpretableSymbol, NonInterpretableSymbol, SymbolId} from "../dream-form/symbols";
import {PageLayout} from "../../template/PageLayout";
import {
  calculateAverage,
  calculateQuadrantFromAverages,
  iconFromAverages,
  isInsideFirst,
  precisionFromNumberOfSymbols,
  saveDream
} from "./actions";
import {AppBar, Grid, MobileStepper} from "@material-ui/core";
import {ResultChart} from "../../components/ResultChart";
import {ResultDescription} from "../../components/ResultDescription";
import {FeedbackSharp} from "@material-ui/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CustomButton as Button} from "../../components/CustomButton";
import {useRedirectTo} from "../../helpers/hooks";
import SymbolSelector from "./SymbolsSelector";
import {Quadrant} from "./icons";
import SymbolArt from "./SymbolArt";

interface ResultPropsInterface {
  selectedSymbols: InterpretableSymbol[],
  selectedWhereSymbols: NonInterpretableSymbol[]
}

const Result = () => {
  const location: { state: ResultPropsInterface } = useLocation()
  const redirectTo = useRedirectTo()

  const [x, setX] = useState<number>()
  const [y, setY] = useState<number>()
  const [openImages, setOpenImages] = useState<[SymbolId, string] | null>(null)
  const [selectedSymbols, setSelectedSymbols] = useState<InterpretableSymbol[]>()
  const [whereSelectedSymbols, setWhereSelectedSymbols] = useState<NonInterpretableSymbol[]>()
  const [quadrant, setQuadrant] = useState<Quadrant | null>(null)

  useEffect(() => {
    const symbols = location.state ? location.state.selectedSymbols : []
    const whereSymbols = location.state ? location.state.selectedWhereSymbols : []

    if (symbols.length === 0)
      redirectTo("/")
    else {
      const [xAverage, yAverage] = calculateAverage(symbols)
      setX(xAverage)
      setY(yAverage)
      setSelectedSymbols(symbols)
      setWhereSelectedSymbols(whereSymbols)
      xAverage && yAverage && setQuadrant(calculateQuadrantFromAverages(xAverage, yAverage))
      saveDream(xAverage!, yAverage!, [...symbols, ...whereSymbols])
    }
  }, [location])


  const useStyles = makeStyles((theme) => ({
    progressBar: {
      position: "fixed",
      top: 'auto',
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
    },
    graph: {
      width: "80%",
      alignSelf: "center",
      marginTop: 25
    },
    resultExplanation: {
      width: "60%",
      textAlign: "center",
      margin: "auto",
      marginBottom: 60,
      color: theme.palette.primary.dark
    }
  }));

  const classes = useStyles()

  return (<>
        {
          x !== undefined && y !== undefined && whereSelectedSymbols && selectedSymbols &&
          <PageLayout clickOnLogo={() => redirectTo("/")}>
            {openImages
                ? <SymbolArt
                    quadrant={quadrant}
                    symbolId={openImages[0]}
                    title={openImages[1]}
                    onClose={() => setOpenImages(null)}/>
                : <>
                  <Grid container
                        item
                        xs={12}
                        alignItems="center"
                        className={classes.graph}
                        justify="center">
                    <ResultChart x={x} y={y}/>
                  </Grid>
                  <Grid container
                        item
                        xs={12}
                        alignItems="center">
                    <ResultDescription
                        icon={iconFromAverages(x, y, isInsideFirst(whereSelectedSymbols), selectedSymbols.length)}
                        x={x.toFixed(1)}
                        y={y.toFixed(1)}
                        precision={precisionFromNumberOfSymbols(selectedSymbols.length)}
                    />

                     <SymbolSelector
                        selectedSymbols={selectedSymbols}
                        openImagesForSymbol={
                          (symbolId, symbolText) => setOpenImages([symbolId, symbolText])
                        }
                    />
                  </Grid>
                </>
            }
            <AppBar position="fixed" className={classes.progressBar}>
              <MobileStepper
                  steps={0}
                  activeStep={0}
                  variant="dots"
                  position="static"
                  nextButton={
                    <Button
                        onClick={() => window.open("https://form.typeform.com/to/SFQu9Bxo?", "_blank")}
                        startIcon={<FeedbackSharp/>}
                        label="Feedback"
                        size='small'/>
                  }
                  backButton={
                    <Button
                        onClick={() => redirectTo("/")}
                        size="small"
                        label="Ricomincia"
                        dataTestId="previous-step-button"/>
                  }
              />
            </AppBar>
          </PageLayout>
        }
      </>
  )
}


export default Result