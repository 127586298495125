export interface ResultIcon {
  src: string
  alt: string
  description: string
}

export enum Quadrant {
  topLeftQuadrant = "topLeftQuadrant",
  topRightQuadrant="topRightQuadrant",
  bottomLeftQuadrant="bottomLeftQuadrant",
  bottomRightQuadrant="bottomRightQuadrant",
}

export interface ResultQuadrant {
  topLeft: ResultIcon[],
  topRight: ResultIcon[],
  bottomLeft: ResultIcon[],
  bottomRight: ResultIcon[],
}

export const INSIDE_RESULT_CHART: Record<Quadrant, ResultQuadrant>  = {
  topLeftQuadrant: {
    topLeft: [
      {src: "results/top-left-inside-1-0.png", alt: "top-left-inside-1-0", description: "Senza forze"},
      {src: "results/top-left-inside-1-1.png", alt: "top-left-inside-1-1", description: "Ti punisci"},
      {src: "results/top-left-inside-1-2.png", alt: "top-left-inside-1-2", description: "Ti tormenti"},
      {src: "results/top-left-inside-1-3.png", alt: "top-left-inside-1-3", description: "Arrabbiato con te stesso"},
      {src: "results/top-left-inside-1-4.png", alt: "top-left-inside-1-4", description: "Distruttivo"}
    ],
    topRight: [
      {src: "results/top-left-inside-2-0.png", alt: "top-left-inside-2-0", description: "Abulico"},
      {src: "results/top-left-inside-2-1.png", alt: "top-left-inside-2-1", description: "Auto-limitato"},
      {src: "results/top-left-inside-2-2.png", alt: "top-left-inside-2-2", description: "In controllo di te stesso"},
      {src: "results/top-left-inside-2-3.png", alt: "top-left-inside-2-3", description: "Incolpando te stesso"},
      {src: "results/top-left-inside-2-4.png", alt: "top-left-inside-2-4", description: "Paura di te stesso"}
    ],
    bottomLeft: [
      {src: "results/top-left-inside-3-0.png", alt: "top-left-inside-3-0", description: "Preoccupato"},
      {src: "results/top-left-inside-3-1.png", alt: "top-left-inside-3-1", description: "Diffidi di te stesso"},
      {src: "results/top-left-inside-3-2.png", alt: "top-left-inside-3-2", description: "Ti accusi"},
      {src: "results/top-left-inside-3-3.png", alt: "top-left-inside-3-3", description: "Non credi in te stesso"},
      {src: "results/top-left-inside-3-4.png", alt: "top-left-inside-3-4", description: "Minacci te stesso"}
    ],
    bottomRight: [
      {src: "results/top-left-inside-4-0.png", alt: "top-left-inside-4-0", description: "Senza tregua"},
      {src: "results/top-left-inside-4-1.png", alt: "top-left-inside-4-1", description: "Confuso su te stesso"},
      {src: "results/top-left-inside-4-2.png", alt: "top-left-inside-4-2", description: "Sarcastico"},
      {src: "results/top-left-inside-4-3.png", alt: "top-left-inside-4-3", description: "Critico con te stesso"},
      {src: "results/top-left-inside-4-4.png", alt: "top-left-inside-4-4", description: "Incurante di te stesso"}
    ]
  },
  topRightQuadrant: {
    topLeft: [
      {src: "results/top-right-inside-1-0.png", alt: "top-right-inside-1-0", description: "Giocoso"},
      {src: "results/top-right-inside-1-1.png", alt: "top-right-inside-1-1", description: "In sfida con te stesso"},
      {src: "results/top-right-inside-1-2.png", alt: "top-right-inside-1-2", description: "Passionale"},
      {src: "results/top-right-inside-1-3.png", alt: "top-right-inside-1-3", description: "Confidente in te stesso"},
      {src: "results/top-right-inside-1-4.png", alt: "top-right-inside-1-4", description: "Entusiasta di te stesso"}
    ],
    topRight: [
      {src: "results/top-right-inside-2-0.png", alt: "top-right-inside-2-0", description: "Gioioso"},
      {src: "results/top-right-inside-2-1.png", alt: "top-right-inside-2-1", description: "Sicuro di te stesso"},
      {src: "results/top-right-inside-2-2.png", alt: "top-right-inside-2-2", description: "In pace con te stesso"},
      {src: "results/top-right-inside-2-3.png", alt: "top-right-inside-2-3", description: "Felice di te stesso"},
      {src: "results/top-right-inside-2-4.png", alt: "top-right-inside-2-4", description: "Pienamente soddisfatto di te"}
    ],
    bottomLeft: [
      {src: "results/top-right-inside-3-0.png", alt: "top-right-inside-3-0", description: "A tuo agio"},
      {src: "results/top-right-inside-3-1.png", alt: "top-right-inside-3-1", description: "Comprensivo di te stesso"},
      {src: "results/top-right-inside-3-2.png", alt: "top-right-inside-3-2", description: "Ti accetti"},
      {src: "results/top-right-inside-3-3.png", alt: "top-right-inside-3-3", description: "Ti apprezzi"},
      {src: "results/top-right-inside-3-4.png", alt: "top-right-inside-3-4", description: "Aperto a nuove esperienze"}
    ],
    bottomRight: [
      {src: "results/top-right-inside-4-0.png", alt: "top-right-inside-4-0", description: "Allegro"},
      {src: "results/top-right-inside-4-1.png", alt: "top-right-inside-4-1", description: "Fiducioso in te stesso"},
      {src: "results/top-right-inside-4-2.png", alt: "top-right-inside-4-2", description: "Buono cn te stesso"},
      {src: "results/top-right-inside-4-3.png", alt: "top-right-inside-4-3", description: "Credi a te stesso"},
      {src: "results/top-right-inside-4-4.png", alt: "top-right-inside-4-4", description: "Elettrizzato"}
    ]
  },
  bottomLeftQuadrant: {
    topLeft: [
      {src: "results/bottom-left-inside-1-0.png", alt: "bottom-left-inside-1-0", description: "Irritato"},
      {src: "results/bottom-left-inside-1-1.png", alt: "bottom-left-inside-1-1", description: "Ti fai del male"},
      {src: "results/bottom-left-inside-1-2.png", alt: "bottom-left-inside-1-2", description: "Tradisci te stesso"},
      {src: "results/bottom-left-inside-1-3.png", alt: "bottom-left-inside-1-3", description: "Non ti fidi di te stesso"},
      {src: "results/bottom-left-inside-1-4.png", alt: "bottom-left-inside-1-4", description: "Inganni te stesso"}
    ],
    topRight: [
      {src: "results/bottom-left-inside-2-0.png", alt: "bottom-left-inside-2-0", description: "Annoiato"},
      {src: "results/bottom-left-inside-2-1.png", alt: "bottom-left-inside-2-1", description: "Preoccupato per te stesso"},
      {src: "results/bottom-left-inside-2-2.png", alt: "bottom-left-inside-2-2", description: "Dubbioso di te stesso"},
      {src: "results/bottom-left-inside-2-3.png", alt: "bottom-left-inside-2-3", description: "Sfiduciato di te stesso"},
      {src: "results/bottom-left-inside-2-4.png", alt: "bottom-left-inside-2-4", description: "Deluso di te stesso"}
    ],
    bottomLeft: [
      {src: "results/bottom-left-inside-3-0.png", alt: "bottom-left-inside-3-0", description: "Senza senso"},
      {src: "results/bottom-left-inside-3-1.png", alt: "bottom-left-inside-3-1", description: "Spaventato di te stesso"},
      {src: "results/bottom-left-inside-3-2.png", alt: "bottom-left-inside-3-2", description: "Ti allontani da te stesso"},
      {src: "results/bottom-left-inside-3-3.png", alt: "bottom-left-inside-3-3", description: "Abbandonato da te stesso"},
      {src: "results/bottom-left-inside-3-4.png", alt: "bottom-left-inside-3-4", description: "Depresso"}
    ],
    bottomRight: [
      {src: "results/bottom-left-inside-4-0.png", alt: "bottom-left-inside-4-0", description: "Avventato"},
      {src: "results/bottom-left-inside-4-1.png", alt: "bottom-left-inside-4-1", description: "Ti metti in pericolo"},
      {src: "results/bottom-left-inside-4-2.png", alt: "bottom-left-inside-4-2", description: "Ribelle con te stesso"},
      {src: "results/bottom-left-inside-4-3.png", alt: "bottom-left-inside-4-3", description: "Perso i te stesso"},
      {src: "results/bottom-left-inside-4-4.png", alt: "bottom-left-inside-4-4", description: "Ti isoli"}
    ]
  },
  bottomRightQuadrant: {
    topLeft: [
      {src: "results/bottom-right-inside-1-0.png", alt: "bottom-right-inside-1-0", description: "Calmo"},
      {src: "results/bottom-right-inside-1-1.png", alt: "bottom-right-inside-1-1", description: "Tollerante con te stesso"},
      {src: "results/bottom-right-inside-1-2.png", alt: "bottom-right-inside-1-2", description: "Coccolando te stesso"},
      {src: "results/bottom-right-inside-1-3.png", alt: "bottom-right-inside-1-3", description: "In ascolto di te stesso"},
      {src: "results/bottom-right-inside-1-4.png", alt: "bottom-right-inside-1-4", description: "Esercitando te stesso"}
    ],
    topRight: [
      {src: "results/bottom-right-inside-2-0.png", alt: "bottom-right-inside-2-0", description: "A tuo agio con te stesso"},
      {src: "results/bottom-right-inside-2-1.png", alt: "bottom-right-inside-2-1", description: "Potente"},
      {src: "results/bottom-right-inside-2-2.png", alt: "bottom-right-inside-2-2", description: "Attento a te stesso"},
      {src: "results/bottom-right-inside-2-3.png", alt: "bottom-right-inside-2-3", description: "Educando te stesso"},
      {src: "results/bottom-right-inside-2-4.png", alt: "bottom-right-inside-2-4", description: "Prendendoti cura di te stesso"}
    ],
    bottomLeft: [
      {src: "results/bottom-right-inside-3-0.png", alt: "bottom-right-inside-3-0", description: "Reattivo"},
      {src: "results/bottom-right-inside-3-1.png", alt: "bottom-right-inside-3-1", description: "Preoccupato di te stesso"},
      {src: "results/bottom-right-inside-3-2.png", alt: "bottom-right-inside-3-2", description: "Dipendente da te stesso"},
      {src: "results/bottom-right-inside-3-3.png", alt: "bottom-right-inside-3-3", description: "In ansia per te stesso"},
      {src: "results/bottom-right-inside-3-4.png", alt: "bottom-right-inside-3-4", description: "Molto attento a te stesso"}
    ],
    bottomRight: [
      {src: "results/bottom-right-inside-4-0.png", alt: "bottom-right-inside-4-0", description: "Energico"},
      {src: "results/bottom-right-inside-4-1.png", alt: "bottom-right-inside-4-1", description: "Protettivo di te stesso"},
      {src: "results/bottom-right-inside-4-2.png", alt: "bottom-right-inside-4-2", description: "Cocentrato su te stesso"},
      {src: "results/bottom-right-inside-4-3.png", alt: "bottom-right-inside-4-3", description: "Conti su te stesso"},
      {src: "results/bottom-right-inside-4-4.png", alt: "bottom-right-inside-4-4", description: "Esigente con te stesso"}
    ]
  }
}

export const OUTSIDE_RESULT_CHART: Record<Quadrant, ResultQuadrant>  = {
  topLeftQuadrant: {
    topLeft: [
      {src: "results/top-left-outside-1-0.png", alt: "top-left-outside-1-0", description: "Indifeso"},
      {src: "results/top-left-outside-1-1.png", alt: "top-left-outside-1-1", description: "Punisci altri"},
      {src: "results/top-left-outside-1-2.png", alt: "top-left-outside-1-2", description: "Tormanti altri"},
      {src: "results/top-left-outside-1-3.png", alt: "top-left-outside-1-3", description: "Arrabbiato con altri"},
      {src: "results/top-left-outside-1-4.png", alt: "top-left-outside-1-4", description: "Tragico"}
    ],
    topRight: [
      {src: "results/top-left-outside-2-0.png", alt: "top-left-outside-2-0", description: "Sonnolento"},
      {src: "results/top-left-outside-2-1.png", alt: "top-left-outside-2-1", description: "Limitato da altri"},
      {src: "results/top-left-outside-2-2.png", alt: "top-left-outside-2-2", description: "In controllo di altri"},
      {src: "results/top-left-outside-2-3.png", alt: "top-left-outside-2-3", description: "Incolpando altri"},
      {src: "results/top-left-outside-2-4.png", alt: "top-left-outside-2-4", description: "Paura degli altri"}
    ],
    bottomLeft: [
      {src: "results/top-left-outside-3-0.png", alt: "top-left-outside-3-0", description: "Ansioso"},
      {src: "results/top-left-outside-3-1.png", alt: "top-left-outside-3-1", description: "Diffidi di altri"},
      {src: "results/top-left-outside-3-2.png", alt: "top-left-outside-3-2", description: "Accusi altri"},
      {src: "results/top-left-outside-3-3.png", alt: "top-left-outside-3-3", description: "Non credi negli altri"},
      {src: "results/top-left-outside-3-4.png", alt: "top-left-outside-3-4", description: "Minacci altri"}
    ],
    bottomRight: [
      {src: "results/top-left-outside-4-0.png", alt: "top-left-outside-4-0", description: "Iperattivo"},
      {src: "results/top-left-outside-4-1.png", alt: "top-left-outside-4-1", description: "Confuso su altri"},
      {src: "results/top-left-outside-4-2.png", alt: "top-left-outside-4-2", description: "Provocatorio"},
      {src: "results/top-left-outside-4-3.png", alt: "top-left-outside-4-3", description: "Critico con altri"},
      {src: "results/top-left-outside-4-4.png", alt: "top-left-outside-4-4", description: "Incurante di altri"}
    ]
  },
  topRightQuadrant: {
    topLeft: [
      {src: "results/top-right-outside-1-0.png", alt: "top-right-outside-1-0", description: "Scherzoso"},
      {src: "results/top-right-outside-1-1.png", alt: "top-right-outside-1-1", description: "In sfida con altri"},
      {src: "results/top-right-outside-1-2.png", alt: "top-right-outside-1-2", description: "Appassionato ad altri"},
      {src: "results/top-right-outside-1-3.png", alt: "top-right-outside-1-3", description: "Confidente negli altr"},
      {src: "results/top-right-outside-1-4.png", alt: "top-right-outside-1-4", description: "Entusiasta di altri"}
    ],
    topRight: [
      {src: "results/top-right-outside-2-0.png", alt: "top-right-outside-2-0", description: "Ottimistico"},
      {src: "results/top-right-outside-2-1.png", alt: "top-right-outside-2-1", description: "Sicuro degli altri"},
      {src: "results/top-right-outside-2-2.png", alt: "top-right-outside-2-2", description: "In pace con gli altri"},
      {src: "results/top-right-outside-2-3.png", alt: "top-right-outside-2-3", description: "Felice degli altri"},
      {src: "results/top-right-outside-2-4.png", alt: "top-right-outside-2-4", description: "Pienamente soddisfatto di tutto"}
    ],
    bottomLeft: [
      {src: "results/top-right-outside-3-0.png", alt: "top-right-outside-3-0", description: "Accogliente"},
      {src: "results/top-right-outside-3-1.png", alt: "top-right-outside-3-1", description: "Comprensivo degli altri"},
      {src: "results/top-right-outside-3-2.png", alt: "top-right-outside-3-2", description: "Accetti gli altri"},
      {src: "results/top-right-outside-3-3.png", alt: "top-right-outside-3-3", description: "Apprezzi gli altri"},
      {src: "results/top-right-outside-3-4.png", alt: "top-right-outside-3-4", description: "Aperto a nuovi incontri"}
    ],
    bottomRight: [
      {src: "results/top-right-outside-4-0.png", alt: "top-right-outside-4-0", description: "Entusiasta"},
      {src: "results/top-right-outside-4-1.png", alt: "top-right-outside-4-1", description: "Fiducioso negli altri"},
      {src: "results/top-right-outside-4-2.png", alt: "top-right-outside-4-2", description: "Buono con gli altri"},
      {src: "results/top-right-outside-4-3.png", alt: "top-right-outside-4-3", description: "Credi negli altri"},
      {src: "results/top-right-outside-4-4.png", alt: "top-right-outside-4-4", description: "Felicissimo"}
    ]
  },
  bottomLeftQuadrant: {
    topLeft: [
      {src: "results/bottom-left-outside-1-0.png", alt: "bottom-left-outside-1-0", description: "Infastidito da altri"},
      {src: "results/bottom-left-outside-1-1.png", alt: "bottom-left-outside-1-1", description: "Preoccupato per altri"},
      {src: "results/bottom-left-outside-1-2.png", alt: "bottom-left-outside-1-2", description: "Dubbioso di altri"},
      {src: "results/bottom-left-outside-1-3.png", alt: "bottom-left-outside-1-3", description: "Sfiduciato di altri"},
      {src: "results/bottom-left-outside-1-4.png", alt: "bottom-left-outside-1-4", description: "Deluso di altri"}
    ],
    topRight: [
      {src: "results/bottom-left-outside-2-0.png", alt: "bottom-left-outside-2-0", description: "Turbato"},
      {src: "results/bottom-left-outside-2-1.png", alt: "bottom-left-outside-2-1", description: "Fai male ad altri"},
      {src: "results/bottom-left-outside-2-2.png", alt: "bottom-left-outside-2-2", description: "Tradisci altri"},
      {src: "results/bottom-left-outside-2-3.png", alt: "bottom-left-outside-2-3", description: "Non ti fidi di altri"},
      {src: "results/bottom-left-outside-2-4.png", alt: "bottom-left-outside-2-4", description: "Inganni altri"}
    ],
    bottomLeft: [
      {src: "results/bottom-left-outside-3-0.png", alt: "bottom-left-outside-3-0", description: "Distrutto"},
      {src: "results/bottom-left-outside-3-1.png", alt: "bottom-left-outside-3-1", description: "Spaventato di altri"},
      {src: "results/bottom-left-outside-3-2.png", alt: "bottom-left-outside-3-2", description: "Ti allontani da altri"},
      {src: "results/bottom-left-outside-3-3.png", alt: "bottom-left-outside-3-3", description: "Abbandonato da altri"},
      {src: "results/bottom-left-outside-3-4.png", alt: "bottom-left-outside-3-4", description: "Disperato"}
    ],
    bottomRight: [
      {src: "results/bottom-left-outside-4-0.png", alt: "bottom-left-outside-4-0", description: "Impulsivo"},
      {src: "results/bottom-left-outside-4-1.png", alt: "bottom-left-outside-4-1", description: "Metti in pericolo altri"},
      {src: "results/bottom-left-outside-4-2.png", alt: "bottom-left-outside-4-2", description: "Ribelle con gli altri"},
      {src: "results/bottom-left-outside-4-3.png", alt: "bottom-left-outside-4-3", description: "Perso nel mondo"},
      {src: "results/bottom-left-outside-4-4.png", alt: "bottom-left-outside-4-4", description: "Senza direzione"}
    ]
  },
  bottomRightQuadrant: {
    topLeft: [
      {src: "results/bottom-right-outside-1-0.png", alt: "bottom-right-outside-1-0", description: "Moderato"},
      {src: "results/bottom-right-outside-1-1.png", alt: "bottom-right-outside-1-1", description: "Tollerante con altri"},
      {src: "results/bottom-right-outside-1-2.png", alt: "bottom-right-outside-1-2", description: "Coccolando altri"},
      {src: "results/bottom-right-outside-1-3.png", alt: "bottom-right-outside-1-3", description: "In ascolto di altri"},
      {src: "results/bottom-right-outside-1-4.png", alt: "bottom-right-outside-1-4", description: "Esercitando altri"}
    ],
    topRight: [
      {src: "results/bottom-right-outside-2-0.png", alt: "bottom-right-outside-2-0", description: "A tuo agio con altri"},
      {src: "results/bottom-right-outside-2-1.png", alt: "bottom-right-outside-2-1", description: "Dinamico"},
      {src: "results/bottom-right-outside-2-2.png", alt: "bottom-right-outside-2-2", description: "Attento ad altri"},
      {src: "results/bottom-right-outside-2-3.png", alt: "bottom-right-outside-2-3", description: "Educando altri"},
      {src: "results/bottom-right-outside-2-4.png", alt: "bottom-right-outside-2-4", description: "Prendendoti cura di altri"}
    ],
    bottomLeft: [
      {src: "results/bottom-right-outside-3-0.png", alt: "bottom-right-outside-3-0", description: "Consapevole"},
      {src: "results/bottom-right-outside-3-1.png", alt: "bottom-right-outside-3-1", description: "Preoccupato di altri"},
      {src: "results/bottom-right-outside-3-2.png", alt: "bottom-right-outside-3-2", description: "Dipendente da altri"},
      {src: "results/bottom-right-outside-3-3.png", alt: "bottom-right-outside-3-3", description: "In ansia per altri"},
      {src: "results/bottom-right-outside-3-4.png", alt: "bottom-right-outside-3-4", description: "Molto attento ad altri"}
    ],
    bottomRight: [
      {src: "results/bottom-right-outside-4-0.png", alt: "bottom-right-outside-4-0", description: "Efficace"},
      {src: "results/bottom-right-outside-4-1.png", alt: "bottom-right-outside-4-1", description: "Protettivo di altri"},
      {src: "results/bottom-right-outside-4-2.png", alt: "bottom-right-outside-4-2", description: "Concentrato su altri"},
      {src: "results/bottom-right-outside-4-3.png", alt: "bottom-right-outside-4-3", description: "Conti su altri"},
      {src: "results/bottom-right-outside-4-4.png", alt: "bottom-right-outside-4-4", description: "Esigente con altri"}
    ]
  }
}