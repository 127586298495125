import React from "react";
import {InterpretableSymbol, SymbolId} from "../dream-form/symbols";
import {
  Avatar,
  Backdrop,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SwipeableDrawer,
  Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Brush} from '@material-ui/icons'


interface SymbolSelectorPropsInterface {
  selectedSymbols: InterpretableSymbol[],
  openImagesForSymbol: (symbolId: SymbolId, symbolText: string) => void
}

const SymbolSelector = ({selectedSymbols, openImagesForSymbol}: SymbolSelectorPropsInterface) => {
  const [imagesOpen, setImagesOpen] = React.useState(false);
  const handleOpenImages = () => setImagesOpen(true);
  const handleCloseImages = () => setImagesOpen(false);


  const useStyles = makeStyles((theme) => ({
    symbolSelector: {
      position: 'fixed',
      bottom: 80,
      right: 75,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.dark,
      textAlign: 'center',
      padding: "5px 10px"
    },
    paintIcon: {
      width: 56,
      height: 56,
      backgroundColor: theme.palette.secondary.dark,
      marginLeft: 8
    },
    paintText: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.dark,
      padding: 4,
      width: '70%',
    },
    paintButton: {
      alignItems: 'center',
      bottom: 70,
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center'
    }

  }));

  const classes = useStyles()

  return (
      <>
        <Grid container
              item
              xs={12}
              alignItems="center"
              className={classes.paintButton}>

          <Paper className={classes.paintText}>
            <Typography onClick={handleOpenImages}>Quale simbolo ti incuriosisce di piú?</Typography>
          </Paper>
          <Avatar onClick={handleOpenImages} className={classes.paintIcon}>
            <Brush/>
          </Avatar>
        </Grid>
        {imagesOpen && <Paper className={classes.symbolSelector} elevation={2}>
          <Backdrop open={imagesOpen}/>

          <SwipeableDrawer
              anchor={'top'}
              open={imagesOpen}
              onClose={handleCloseImages}
              onOpen={handleOpenImages}
          >
            <Box
                style={{width: 'auto'}}
                role="presentation"
                onClick={handleCloseImages}
                onKeyDown={handleCloseImages}
            >
              <List>
                {selectedSymbols.map((symbol, index) => (
                    <ListItem button key={`symbol-${symbol.id}`}
                              onClick={() => openImagesForSymbol(symbol.id, symbol.label)}>
                      <ListItemIcon>
                        <Avatar alt={symbol.label} src={symbol.icon || undefined}/>
                      </ListItemIcon>
                      <ListItemText primary={symbol.label}/>
                    </ListItem>
                ))}
              </List>
            </Box>
          </SwipeableDrawer>
        </Paper>
        }
      </>

  )
};

export default SymbolSelector