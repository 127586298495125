import React from "react";
import {Grid} from "@material-ui/core";
import {SymbolCard} from "../../components/SymbolCard";
import {Symbol} from "../../pages/dream-form/symbols";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface SymbolGridTemplateInterface {
  symbols: Symbol[],
  selectedSymbols: Symbol[],
  onClick: (id: Symbol) => void
}

const useStyles = makeStyles((theme) => ({
  symbolsGrid: {
    paddingTop: '10px'
  },
}));

export const SymbolGridTemplate = (props: SymbolGridTemplateInterface) => {
  const classes = useStyles();
  const isSymbolSelected = (symbolId: string) => {
    return !!props.selectedSymbols.find(symbol => symbol.id === symbolId)
  }

  return (
        <Grid
            container
            justify="flex-start"
            item xs={12}
            className={classes.symbolsGrid}
        >
          {props.symbols.map((symbol, index) =>
              <Grid
                  container
                  justify="center"
                  item xs={4}
                  key={`symbol-${index}`}>
                <SymbolCard label={symbol.label}
                            symbolSize={100}
                            symbolImage={symbol.icon}
                            isSelected={isSymbolSelected(symbol.id)}
                            onClick={() => props.onClick(symbol)}/>
              </Grid>)
          }
        </Grid>
  )
}