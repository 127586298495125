import {Grid, Typography} from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface ResultChartTemplateProps {
    topLabel: string,
    rightLabel: string,
    bottomLabel: string,
    leftLabel: string,
    chart: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
    resultChartLabel: {
        marginTop: 0
    },
    label: {
        color: theme.palette.secondary.dark,
        marginBottom: '15px'
    }
}));

export const ResultChartTemplate = (props: ResultChartTemplateProps) => {
    const classes = useStyles();
    return (
        <>
            <Grid container
                  data-testid="result-chart"
                  justify="center"
                  alignItems="center"
                  direction="row"
                  item xs={12}>
                <Grid container
                      justify="center"
                      direction="column"
                      alignItems="center"
                      item xs={6}>
                    <Typography className={classes.label}>
                        {props.topLabel}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container
                  justify="center"
                  direction="row"
                  className="tryFla"
                  item xs={12} sm={8}>
                <Grid container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      item xs={3} sm={2}>
                    <Typography className={classes.label}>
                        {props.leftLabel}
                    </Typography>
                </Grid>
                <Grid container
                      className={"tryFla"}
                      justify="center"
                      direction="column"
                      item xs={6} sm={4}>
                    {props.chart}
                </Grid>
                <Grid container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      item xs={3} sm={2}>
                    <Typography className={classes.label}>
                        {props.rightLabel}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container
                  justify="center"
                  direction="row">
                <Grid container
                      justify="center"
                      alignItems="center"
                      direction="column"
                      item xs={6}>
                    <Typography className={classes.label}>
                        {props.bottomLabel}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}