import React from "react";
import {ButtonInterface} from "../../components/CustomButton";
import {Header} from "../../components/Header";
import {Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface PageLayoutInterface {
  topRightButton?: ButtonInterface,
  clickOnLogo: () => void,
  children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  layout: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main
  }
}));

export const PageLayout = (props: PageLayoutInterface) => {
  const classes = useStyles();

  return (
      <>
        <Header
            clickOnLogo={props.clickOnLogo}
            topRightButton={props.topRightButton}/>
        <Grid container
              direction="column"
              justify="center"
              className={classes.layout}
        >
          {props.children}
        </Grid>
      </>
  )
}

