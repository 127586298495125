import React from "react"
import {SymbolId} from "../dream-form/symbols"
import {Quadrant} from "./icons"
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Card, CardActions, CardHeader, CardMedia, IconButton, MobileStepper} from "@material-ui/core";
import {Clear, KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";
import {autoPlay} from "react-swipeable-views-utils";


interface SymbolArtPropsInterface {
  symbolId: SymbolId,
  title: string,
  quadrant: Quadrant | null
  onClose: () => void
}

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const makeSshForSymbol = (symbolId: string, quadrant: string) =>
    ['a', 'b', 'c', 'd'].map(appendix =>
        `https://revealresultsart.s3.eu-central-1.amazonaws.com/ssh+500x350+/${symbolId}_ssh_${quadrant}_${appendix}.png`)

const SymbolArt = ({symbolId, quadrant, title, onClose}: SymbolArtPropsInterface) => {

  const images = quadrant === Quadrant.topRightQuadrant
      ? [`art/${symbolId}_art-1.png`, ...makeSshForSymbol(symbolId, '1')]
      : quadrant === Quadrant.bottomRightQuadrant
          ? [`art/${symbolId}_art-4.png`, ...makeSshForSymbol(symbolId, '4')]
          : quadrant === Quadrant.bottomLeftQuadrant
              ? [`art/${symbolId}_art-3.png`, ...makeSshForSymbol(symbolId, '3')]
              : [`art/${symbolId}_art-2.png`, ...makeSshForSymbol(symbolId, '2')]

  const useStyles = makeStyles((theme) => ({
    painting: {
      [theme.breakpoints.up('md')]: {
        maxWidth: '40%'
      },
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 30
    },
    swipeable: {
      height: 350,
      [theme.breakpoints.up('md')]: {
        height: 500
      },
    },
    image: {
      width: '90%',
      display: "block",
      margin: "auto",
    },
    cardActions: {
      justifyContent: 'center'
    },
    stepper: {
      justifyContent: 'space-between',
      width: '100%',
    },
  }))

  const classes = useStyles()

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
      <Card variant="outlined" className={classes.painting}>
        <CardHeader
            action={
              <IconButton aria-label="settings" onClick={onClose}>
                <Clear/>
              </IconButton>
            }
            title={title}
            subheader="Ecco un imagine che ha a che vedere con il tuo sogno"
        />
        <Box className={classes.swipeable}>
          <AutoPlaySwipeableViews
              axis={'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
          >
            {images.map((image, index) => (
                <div key={`art-step-${index}}`}>
                      <CardMedia
                          className={classes.image}
                          component="img"
                          image={image}
                          title={symbolId}
                      />
                </div>
            ))}
          </AutoPlaySwipeableViews>
        </Box>
        <CardActions className={classes.cardActions}>
          <MobileStepper
              className={classes.stepper}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                >
                  Next
                  <KeyboardArrowRight/>
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft/>
                  Back
                </Button>
              }
          />
        </CardActions>
      </Card>
  )
}

export default SymbolArt