import React from "react";
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";


export interface SymbolInterface {
    onClick?: () => void,
    label: string,
    symbolImage: string | null,
    isSelected?: boolean,
    symbolSize: number,
    symbolBackground?: string
}

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: "none",
        backgroundColor: "transparent",
        color: theme.palette.secondary.dark,
        overflow: "visible"
    },
    cardActionArea: {
        width: "75%",
        margin: "auto"
    },
    selectedCard: {
        borderWidth: 3,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.dark,
    },
    noSelectedCard: {
        borderWidth: 3,
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.main,
    },
    media: {
        borderStyle: "solid",
        borderRadius: 100,
        width: "90%"
    },
    mediaWhere: {
        border: "2px solid"
    },
    symbolText: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: 8,
    },
    symbolTextSelected: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        paddingTop: 8,
    },
    typography: {
        minWidth: 100,
        fontSize: 14
    },
    symbolImage: {
        height: 80
    },
    box: {
        width: (props: SymbolInterface) => props.symbolSize,
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: 170
    },
}));

export const SymbolCard = (props: SymbolInterface) => {

    const classes = useStyles(props);

    return (<Box onClick={props.onClick} className={classes.box}>
            <Card className={classes.card}>
                {props.symbolImage !== null ?
                    <CardActionArea className={classes.cardActionArea}>
                        <CardMedia
                            data-testid={`image-${props.label}`}
                            className={props.isSelected
                                ? `${classes.media} ${classes.selectedCard}`
                                : `${classes.media} ${classes.noSelectedCard}`}
                            component="img"
                            alt={props.label}
                            image={props.symbolImage}
                            title={props.label}
                        />
                        <CardContent
                            data-testid={`label-${props.label}`}
                            className={classes.symbolText}>
                            <Typography className={classes.typography}>
                                {props.label}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    : <CardActionArea className={classes.cardActionArea}>
                        <CardMedia
                            data-testid={`image-${props.label}`}
                            className={props.isSelected
                              ? `${classes.media} ${classes.selectedCard}`
                              : `${classes.media} ${classes.noSelectedCard}`}
                            component="img"
                            alt={props.label}
                            image={props.symbolBackground!}
                            title={props.label}
                        />
                        <CardContent
                            data-testid={`label-${props.label}`}
                            className={classes.symbolText}>
                            <Typography className={classes.typography}>
                                {props.label}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                }
            </Card>
        </Box>
    )
}
