import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Welcome from '../welcome'
import DreamForm from '../dream-form'
import Result from "../result";

const App = () => {
    return (
        <>
            <Router>
              <Switch>
                <Route  path="/" exact component={Welcome}/>
                <Route path="/dream" exact component={DreamForm}/>
                <Route path="/result" exact component={Result}/>
                <Route><Redirect to={"/"}/></Route>
              </Switch>
            </Router>
        </>
    )
}

export default App