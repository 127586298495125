import {
    Symbol,
    Colors,
    When,
    Animals,
    Where,
    WhereInside,
    WhereOutside,
    Body,
    Dress,
    FoodDrinks,
} from "./symbols"

export enum WhereStepCondition {
    InsideOnly,
    OutSideOnly,
    FirstInsideThenOutside,
    FirstOutsideThenInside
}

export interface Step {
    next?: (whereCondition?: WhereStepCondition) => Step,
    back?: () => Step,
    symbols: () => Symbol[],
    question: string,
    questionExplanation?: string,
    index: number,
    minimumSymbolsNeeded?: number
}

export const totalNumberOfSteps = 8

export const FoodDrinkStep: Step = {
    question: "C'erano cibi o bevande rilevanti?",
    symbols: () => FoodDrinks,
    back: () => DressStep,
    minimumSymbolsNeeded: 2,
    index: 7,
}

export const DressStep: Step = {
    question: "C'erano abiti o accessori rilevanti?",
    symbols: () => Dress,
    next: () => FoodDrinkStep,
    back: () => BodyStep,
    index: 6,
}

export const BodyStep: Step = {
    question: "Quale parte del tuo o altrui corpo era rilevante?",
    symbols: () => Body,
    next: () => DressStep,
    back: () => AnimalStep,
    index: 5,
}

export const AnimalStep: Step = {
    question: "C'erano animali?",
    symbols: () => Animals,
    next: () => BodyStep,
    back: () => WhereStep,
    index: 4,
}

export const WhereOutsideStep: Step = {
    question: "Dove si svolge il tuo sogno?",
    symbols: () => WhereOutside,
    next: (whereCondition) => {
        return  whereCondition === WhereStepCondition.FirstOutsideThenInside
            ? WhereInsideStep : AnimalStep
    },
    back: () => WhereStep,
    index: 3,
}

export const WhereInsideStep: Step = {
    question: "Dove si svolge il tuo sogno?",
    symbols: () => WhereInside,
    next: (whereCondition) => {
        return  whereCondition === WhereStepCondition.FirstInsideThenOutside
            ? WhereOutsideStep : AnimalStep
    },
    back: () => WhereStep,
    index:3,
}

export const WhereStep: Step = {
    question: "Eri all'aperto o in un interno?",
    questionExplanation: "(oppure clicca su entrambe)",
    symbols: () => Where,
    next: (whereCondition) => {
        return whereCondition === WhereStepCondition.InsideOnly
        || whereCondition === WhereStepCondition.FirstInsideThenOutside
        ? WhereInsideStep : WhereOutsideStep
    },
    back: () => WhenStep,
    minimumSymbolsNeeded: 1,
    index: 2,
}

export const WhenStep: Step = {
    question: "Quando si è svolto il tuo sogno?",
    symbols: () => When,
    next: () => WhereStep,
    back: () => ColorStep,
    index: 1,
}

export const ColorStep: Step = {
    question: "Ci sono colori nel tuo sogno?",
    next: () => WhenStep,
    symbols: () => Colors,
    index: 0,
}
