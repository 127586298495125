import React from "react";
import {Step, totalNumberOfSteps} from "../../pages/dream-form/steps";
import {ButtonInterface, CustomButton as Button} from "../../components/CustomButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {AppBar, Grid, MobileStepper, Typography} from "@material-ui/core";
import {useSwipeable} from "react-swipeable";

export interface DreamFormNavigationBarInterface {
  step: Step,
  onClickBack: () => void,
  topRightButton?: ButtonInterface,
  children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  pageHeaderTitle: {
    fontWeight: "normal",
    textAlign: "center",
    minHeight: 65,
    color: theme.palette.secondary.dark,
  },
  pageHeaderSubtitle: {
    textAlign: "center",
    marginTop: -20,
    color: theme.palette.secondary.light,
  },
  pageHeader: {
    position: "sticky",
    top: "72px", // /* TODO to improve this  */
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    padding: "4px 8px",
    width: "100%"
  },
  progressBar: {
    position: "fixed",
    top: 'auto',
    bottom: 0,
  },
  stepper: {
    backgroundColor: theme.palette.secondary.main,
  },
  mainGrid: {
    paddingTop: 8,
    paddingBottom: 32,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.dark,
    maxWidth: '600px'
  }
}));
const swipeConfig = {
  delta: 10,                            // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false,  // call e.preventDefault *See Details*
  trackTouch: true,                     // track touch input
  trackMouse: false,                    // track mouse input
  rotationAngle: 0,                     // set a rotation angle
}
export const DreamFormNavigationBarTemplate = (props: DreamFormNavigationBarInterface) => {
  const onNext = async () => {
    if (props.topRightButton?.onClick) {
      props.topRightButton.onClick()
    }
  }
  const onBack = async () => {
    props.onClickBack()
  }

  const classes = useStyles();
  const handlers = useSwipeable({
    onSwipedLeft: onNext,
    onSwipedRight: onBack,
    ...swipeConfig,
  });


  return (
      <Grid container
            item
            alignItems="center"
            justify="space-around"
            direction="column">
        <Grid item className={classes.pageHeader}>
          <Typography variant="h3" className={classes.pageHeaderTitle} data-testid="dream-form-label">
            {props.step.question}
          </Typography>
          {props.step.questionExplanation && <Typography variant="h4" className={classes.pageHeaderSubtitle} >
            {props.step.questionExplanation}
          </Typography>}
        </Grid>
        <div {...handlers}>
            <Grid
                container
                item xs={12}
                alignItems="center"
                className={classes.mainGrid}>
              {props.children}
            </Grid>

          <AppBar position="fixed" className={classes.progressBar}>
            <MobileStepper
                variant="dots"
                steps={totalNumberOfSteps}
                position="static"
                activeStep={props.step.index}
                className={classes.stepper}
                nextButton={
                  props.topRightButton != null
                      ? <Button
                          size="small"
                          onClick={onNext}
                          label={props.topRightButton.label}
                          dataTestId={props.topRightButton.dataTestId}
                      /> : null
                }
                backButton={
                  <Button
                      onClick={onBack}
                      size="small"
                      label="Indietro"
                      dataTestId="previous-step-button"/>
                }
            />
          </AppBar>
        </div>
      </Grid>
  )
}