import React from "react";
import {Button} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

export interface ButtonInterface {
    startIcon? : React.ReactNode,
    onClick: () => void,
    label: string,
    disabled?: boolean,
    dataTestId?: string,
    size?: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: "none",
        backgroundColor: theme.palette.secondary.dark,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        },
        fontWeight: 'normal',
        color: theme.palette.primary.main
    }
}));


export const CustomButton = (props: ButtonInterface) => {
    const classes = useStyles();

    return (
        <Button className={classes.button}
                size={props.size}
                variant="contained"
                onClick={props.onClick}
                disabled={props.disabled}
                data-testid={props.dataTestId}
                startIcon={props.startIcon}>
            {props.label}
        </Button>
    )
}
