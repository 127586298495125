import {SymbolId} from "../pages/dream-form/symbols";

export const makePostCall = <T>({url, body}: {url: string, body: T}) => {
  fetch(url,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(body),
      })
}
export interface saveDreamRequest {
  x: number,
  y: number,
  symbolIds: SymbolId[]
}

const api = {
  saveDream: (body:saveDreamRequest) => makePostCall<saveDreamRequest>({url: "api", body})
}

export default api