import React from "react";
import {Snackbar} from "@material-ui/core";

export interface NotificationInterface {
  isOpen: boolean,
  onClose: () => void,
  message: string | null,
}

export const Notification = (props: NotificationInterface) => {
  return (
      <Snackbar
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={props.isOpen}
          onClose={props.onClose}
          message={props.message}
      />
  )
}
