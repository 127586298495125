import React from 'react';
import {CustomButton as Button} from "../../components/CustomButton";
import {Box, Grid, Link, Paper} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReactGA from 'react-ga';
import {useRedirectTo} from "../../helpers/hooks";

const useStyles = makeStyles((theme) => ({
  welcomeGrid: {
    minHeight: '90vh',
    color: theme.palette.info.main,
    fontWeight: 5,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    margin: "auto",
    backgroundImage: `url(/iceberg.jpg)`,
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
  },
  headers: {
    textAlign: "center",
    width: "100%",
    paddingTop: 150
  },
  logo: {
    height: 100
  },
  goButton: {
    marginTop: 16
  },
  footer: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    textAlign: "right",
    fontSize: "large",
    paddingTop: 16,
    paddingRight: 16,
    height: 30,
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      margin: "auto",
      height: 70,
      paddingTop: 24,
      paddingRight: 0,
    },
  },
}));


const Welcome = () => {
  const classes = useStyles();
  const redirectTo = useRedirectTo()

  const handleClick = () => {
    ReactGA.event({
      category: "Dream",
      action: "New Dream"
    });
    redirectTo("/dream")
  }

  return (
      <Paper>
        <Grid
            className={classes.welcomeGrid}
            container>
          <Box className={classes.headers}>
            <img className={classes.logo} src='/logo_nameonly_shadow.png' alt="Reveal"/>
          </Box>
          <Box className={classes.goButton} boxShadow={10}>
            <Button
                onClick={handleClick}
                label="Crack your dreams"
                dataTestId="welcome-button"
                size='large'/>
          </Box>
        </Grid>
        <Box className={classes.footer}>
          <Link
              href="https://www.iubenda.com/privacy-policy/60847659"
              title="Privacy Policy"
              color="secondary"
          >Privacy Policy
          </Link>
        </Box>

      </Paper>
  );
}

export default Welcome;
