import React from "react";
import {ButtonInterface, CustomButton as Button} from "../CustomButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box} from "@material-ui/core";

export interface HeaderInterface {
  topRightButton?: ButtonInterface,
  clickOnLogo: () => void,
}

const useStyles = makeStyles((theme) => ({
  logo : {
    color: theme.palette.primary.light,
    "&:hover": {
      cursor: "pointer"
    },
    textAlign: "center"
  },
  logoImage : {
    height: 70
  },
  wrapper: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
}));


export const Header = (props: HeaderInterface) => {
  const classes = useStyles();
  return (
      <Box className={classes.wrapper}>
        <Box className={classes.logo} data-testid="logo" onClick={props.clickOnLogo}>
          <img className={classes.logoImage} src='/logo_nameonly_small.png' alt="Reveal"/>
        </Box>
        <Box>
          {props.topRightButton != null ?
              <Button
                  size="small"
                  onClick={props.topRightButton.onClick}
                  label={props.topRightButton.label}
                  dataTestId={props.topRightButton.dataTestId}
              /> : <></>}
        </Box>
      </Box>
  )
}

