import {Card, CardContent, CardMedia, Grid, Typography} from "@material-ui/core";
import React from "react";
import {ResultIcon} from "../../pages/result/icons";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface ResultDescriptionProps {
    icon: ResultIcon
    x: string
    y: string
    precision: number
}

const useStyles = makeStyles((theme) => ({
    suggestionCard: {
        maxWidth: 100,
        maxHeight: 100,
        display: "block",
        margin: "auto",
    },
    card: {
        boxShadow: "none",
        backgroundColor: "transparent",
    },
    symbolText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    description : {
        color: theme.palette.secondary.main
    },
    precision : {
        color: theme.palette.primary.dark,
        textAlign: "center",
        paddingBottom: 40
    },
    toolTip: {
        color: theme.palette.primary.light
    },
}));

export const ResultDescription = (props: ResultDescriptionProps) => {
    const classes = useStyles();

    return (
        <Grid container
              data-testid="results-description"
              justify="center"
              alignItems="center"
              direction="column">
            <Grid container
                  justify="center"
                  direction="row">
                <Grid container
                      direction="row"
                      justify="center">
                    <Card className={classes.card}>
                        <CardMedia
                            component="img"
                            alt={props.icon.alt}
                            image={props.icon.src}
                            title="A cat"
                            className={classes.suggestionCard}
                            />
                        <CardContent className={classes.symbolText}>
                            <Typography className={classes.description} variant="h6" >{
                                props.icon.description}
                            </Typography>
                            <Typography className={classes.precision}>
                                Livello di precisione in base ai simboli che hai selezionato: <b>{props.precision}%</b>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}