import {Step, WhereStepCondition} from "./steps";
import {NonInterpretableSymbol, Symbol, SymbolId} from "./symbols";

const INSIDE_ID: SymbolId = SymbolId.inside;
const OUTSIDE_ID: SymbolId = SymbolId.outside

const getSelectedSymbolsIds = (selectedSymbols: Symbol[]) => selectedSymbols.map(symbol => symbol.id)

export const selectSymbolAction = (selectedSymbol: Symbol,
                                   selectedSymbols: Symbol[]): Symbol[] => {
  if (getSelectedSymbolsIds(selectedSymbols).includes(selectedSymbol.id)) {
    return selectedSymbols.filter(symbol => symbol.id !== selectedSymbol.id)
  } else {
    return [...selectedSymbols, selectedSymbol]
  }
}

export const getWhereStepCondition = (whereSymbols: NonInterpretableSymbol[]) => {
  const [insideIndex, outsideIndex] = [INSIDE_ID, OUTSIDE_ID]
  .map(whereId => getSelectedSymbolsIds(whereSymbols)
  .findIndex( symbolId => symbolId === whereId))

  if (outsideIndex <0 && insideIndex<0) return WhereStepCondition.FirstInsideThenOutside
  if (outsideIndex <0) return WhereStepCondition.InsideOnly
  if (insideIndex<0) return WhereStepCondition.OutSideOnly
  return insideIndex > outsideIndex
      ? WhereStepCondition.FirstOutsideThenInside
      : WhereStepCondition.FirstInsideThenOutside
}

export const nextStepAction = (whereSymbols: NonInterpretableSymbol[],
                               step: Step): [Step, Symbol[]] => {
  const whereCondition = getWhereStepCondition(whereSymbols)
  const nextStep = step.next!(whereCondition);
  return ([nextStep, nextStep.symbols()])
}

export const previousStepAction = (step: Step): [Step?, Symbol[]?] => {
  if (!step.back)
    return []
  return [step.back(), step.back().symbols()]
}

export const isValidStep = (step: Step, selectedSymbols: Symbol[]): [boolean, string?] => {
  const minimumSymbolsNeeded = step.minimumSymbolsNeeded;

  const isValid = minimumSymbolsNeeded === undefined || minimumSymbolsNeeded <= selectedSymbols.length
  const message = !isValid ? `Seleziona almeno ${minimumSymbolsNeeded} simbol${minimumSymbolsNeeded === 1 ? 'o' :'i'}` : undefined
  return [isValid, message]
}
