import React from 'react';
import ReactDOM from 'react-dom';
import App from "./pages/app";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-196102793-1";
ReactGA.initialize(TRACKING_ID);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff",
      light: '#e2ecef',
      dark: '#6a9dce'
    },
    secondary: {
      main: '#0631fd',
      dark: '#083786',
      light: '#5883cd'
    }
  },
  typography: {
    fontFamily: ['-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'].join(','),
    h1: {
      fontSize: 56,
    },
    h2:  {
      fontSize: 28,
      fontWeight: "bold"
    },
    h3:  {
      fontSize: 25,
    },
    h4:  {
      fontSize: 22,
    },
    body1: {
      fontSize: 20
    },
    fontSize: 20
  }
});

ReactDOM.render(
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>,
    document.getElementById('root')
);
